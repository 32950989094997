<template>
    <v-dialog v-model="dialog" max-width="1100" persistent background-color="white">
        <v-col>
            <v-tabs v-model=tab grow>
                <v-tab v-if=availableItems.generalData>
                    Datos generales
                </v-tab>
                <v-tab v-if=availableItems.contacts>
                    CONTACTOS
                </v-tab>
                <v-tab v-if=availableItems.tlfEMail>
                    TLFS & CORREOS
                </v-tab>
                <v-tab v-if=availableItems.directions>
                    Direcciones
                </v-tab>
                <v-tab v-if=availableItems.preferences>
                    Preferencias
                </v-tab>
                <v-tab v-if=availableItems.paymentTypes>
                    Tipos de pago
                </v-tab>
                <v-tab v-if=availableItems.accountsToPay>
                    Cuentas por pagar
                </v-tab>
                <v-tab v-if=availableItems.uploadFiles>
                    Carga de archivos
                </v-tab>
                <v-tab v-if=availableItems.workTeam>
                    Grupo de trabajo
                </v-tab>
                <v-tab v-if=availableItems.assignWorkEfort>
                    Asignación de tareas
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item class='mt-6' v-if=availableItems.generalData>
                    <v-card flat class='my-2 mx-2'>
                        <v-form ref='form_general_data'>
                            <v-row>
                                <v-col md=4>
                                    <v-col class='text-center primary white--text py-0'>DATOS</v-col>
                                    <v-col>
                                        <v-row>
                                            <v-col class='pt-6'>
                                                <v-text-field v-model="dataSupplier.first_name" dense label="Nombres"
                                                    :rules=required></v-text-field>
                                            </v-col>
                                            <v-col class='pt-6' v-if="path != 'proveedores'">
                                                <v-text-field v-model="dataSupplier.last_name" dense
                                                    label="Apellidos"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class='pt-0' v-if="path == 'proveedores'">
                                        <v-text-field v-model="dataSupplier.bussines_name" dense label="Razón social"
                                            :rules=required></v-text-field>
                                    </v-col>
                                    <v-col class='pt-0' v-if="path == 'proveedores'">
                                        <v-autocomplete v-model=dataSupplier.party_classification_group_id
                                            :items=partyClassificationsGroups item-text="description"
                                            item-value="party_classification_group_id" label="Clasificación" dense
                                            :rules=required></v-autocomplete>
                                    </v-col>
                                    <v-col cols=12 class='pt-0'>
                                        <v-row>
                                            <v-col class='pt-0' cols=12 v-if="path == 'proveedores'">
                                                <v-text-field type='number' min=0 v-model="dataSupplier.prom_gasto" dense
                                                    label="Gasto promedio $" :rules=minVal></v-text-field>
                                            </v-col>
                                            <v-col v-if="path != 'proveedores'">
                                                <v-text-field v-model="dataSupplier.user_login_id" dense label="Usuario"
                                                    :rules=required></v-text-field>
                                            </v-col>
                                            <v-col v-if="path != 'proveedores'">
                                                <v-text-field :type="typeInputPass ? 'text' : 'password'"
                                                    v-model="dataSupplier.password" dense label="Contraseña"
                                                    :append-icon="typeInputPass ? 'mdi-eye' : 'mdi-eye-off'"
                                                    @click:append="typeInputPass = !typeInputPass"></v-text-field>
                                            </v-col>
                                            <v-col cols=12 v-if="path != 'proveedores'">
                                                <v-select v-model=dataSupplier.status :items=status item-text="description"
                                                    item-value="status" label="Estado" dense></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class='pt-0' v-if="path == 'proveedores'">
                                        <v-text-field v-model="dataSupplier.comments" dense
                                            label="Observaciones"></v-text-field>
                                    </v-col>
                                </v-col>
                                <v-col md=4>
                                    <v-col class='text-center primary white--text py-0'>
                                        IDENTIFICACIONES
                                        <v-btn class='mb-1 grey lighten-3' x-small style="right: -2.5rem;top: 2px;"
                                            @click="addIdentification">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <div v-if=dataSupplier.partyIdentifications.length>
                                            <v-row v-for="(identificaiton, index) in dataSupplier.partyIdentifications"
                                                :key=index>
                                                <v-col cols=6 sm=5>
                                                    <v-select
                                                        v-model=dataSupplier.partyIdentifications[index].party_identification_type_id
                                                        :items=typeIdentifications item-text="description"
                                                        item-value="party_identification_type_id" :readonly=false
                                                        label="Tipo" dense class='mt-4' :rules=required></v-select>
                                                </v-col>
                                                <v-col cols=6 sm=7>
                                                    <v-text-field
                                                        v-model="dataSupplier.partyIdentifications[index].id_value" dense
                                                        label="Número" class='mt-4' :rules=required
                                                        append-icon="mdi-delete-forever"
                                                        @click:append="deleteIdentification(dataSupplier.partyIdentifications[index])"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </div>
                                        <div v-else class='mt-2'>
                                            <v-alert border="right" colored-border type="error" elevation="2" dense>
                                                Sin Identificaciones registradas
                                            </v-alert>
                                        </div>
                                    </v-col>
                                </v-col>
                                <v-col md=4>
                                    <v-col class='text-center primary white--text py-0'>
                                        ROLES
                                    </v-col>
                                    <v-col>
                                        <div v-if=dataSupplier.rols.length>
                                            <div v-for="(rol, index) in dataSupplier.rols" :key=index>
                                                <div class='text-caption' v-if="rol.role_type_id != ''">
                                                    {{ index + 1 }}) {{ rol.description }}
                                                    <v-icon
                                                        @click="deleteRol(dataSupplier.rols[index])">mdi-delete-forever</v-icon>
                                                </div>
                                                <div>

                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class='mt-2'>
                                            <v-alert border="right" colored-border type="error" elevation="2" dense>
                                                Sin roles registrados
                                            </v-alert>
                                        </div>
                                        <div class="mt-4">
                                            <v-autocomplete v-model=dataSupplier.others_rols :items=rolTypes
                                                item-text="description" item-value="role_type_id" label="Otros roles"
                                                outlined dense multiple class='mt-2'></v-autocomplete>
                                        </div>
                                    </v-col>
                                </v-col>
                            </v-row>
                            <v-col class='text-center'>
                                <v-btn class="text-center" color='primary' @click="storeGeneralData" :loading=loadingBtn>
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn class="text-center" color='default' @click=closeDialog>
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6' v-if=availableItems.contacts>
                    <v-card flat class='my-2 mx-2'>
                        <v-form ref='form_contacts_data'>
                            <v-row>
                                <v-col>
                                    <v-col class='text-center primary white--text py-0'>
                                        CONTACTOS
                                        <v-btn class='mb-1 grey lighten-3' x-small style="right: -2.5rem;top: 2px;"
                                            @click="addContacts">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col class='pb-0' v-if=dataSupplier.contacts.length>
                                        <v-row v-for="(contact, index) in dataSupplier.contacts" :key=index>
                                            <v-col cols=12 sm=6 md=2>
                                                <v-text-field v-model="contact.first_name" dense label="Nombres"
                                                    :rules=required></v-text-field>
                                            </v-col>
                                            <v-col cols=12 sm=6 md=2>
                                                <v-text-field v-model="contact.last_name" dense label="Apellidos"
                                                    :rules=required></v-text-field>
                                            </v-col>
                                            <v-col cols=12 sm=6 md=2>
                                                <v-text-field v-model="contact.number" v-mask="'593-##-#######'" dense
                                                    label="Teléfono" :rules=required></v-text-field>
                                            </v-col>
                                            <v-col cols=12 sm=6 md=3>
                                                <v-text-field v-model="contact.info_string" dense
                                                    label="Correo"></v-text-field>
                                            </v-col>
                                            <v-col cols=11 sm=5 md=2>
                                                <v-text-field v-model="contact.position_title" dense
                                                    label="Cargo"></v-text-field>
                                            </v-col>
                                            <v-col cols=1>
                                                <v-btn icon color='red' class='mt-2'
                                                    @click=deleteContact(dataSupplier.contacts[index])>
                                                    <v-icon>mdi-delete-forever</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col v-else>
                                        <v-alert border="right" colored-border type="error" elevation="2" dense>
                                            Sin contactos registrados
                                        </v-alert>
                                    </v-col>
                                </v-col>
                            </v-row>
                            <v-col class='text-center'>
                                <v-btn class="text-center" color='primary' @click="storeContacts" :loading=loadingBtn>
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn class="text-center" color='default' @click=closeDialog>
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6' v-if=availableItems.tlfEMail>
                    <v-card flat class='my-2 mx-2'>
                        <v-row>
                            <v-col sm=5 cols="12">
                                <v-col class='text-center primary white--text py-0'>
                                    TELÉFONOS
                                    <v-btn class='mb-1 grey lighten-3' x-small style="top:1px;right:-4rem"
                                        @click="addPhones">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col class='mb-0'>
                                    <div v-if=dataSupplier.phones.length>
                                        <v-row v-for="(phone, index) in dataSupplier.phones" :key=index>
                                            <v-form ref="form_phone_contact">
                                                <v-col cols=12 class='pb-0'>
                                                    <v-text-field v-model="dataSupplier.phones[index].contact_number" dense
                                                        outlined label="Número" v-mask="'593-##-#######'" class='mt-4'
                                                        :rules=required append-icon="mdi-delete-forever"
                                                        @click:append="deletePhone(dataSupplier.phones[index])"></v-text-field>
                                                </v-col>
                                                <v-col cols=12 class='pt-0'>
                                                    <div><b class='text-subtitle-2'>Propositos:</b></div>
                                                    <div v-for="(purpose, index) in phone.party_contact_mech_purposes"
                                                        :key=index>
                                                        <div class='text-caption'
                                                            v-if="purpose.contact_mech_purpose_type_id != ''">
                                                            {{ index + 1 }}) {{ purpose.description }}
                                                            <v-icon
                                                                @click='deletePhonePuporse(phone, purpose)'>mdi-delete-forever</v-icon>
                                                        </div>
                                                        <div v-else>
                                                            <v-autocomplete v-model=purpose.party_contact_mech_purposes
                                                                :items=contactMechPhonePurposeType item-text="description"
                                                                item-value="contact_mech_purpose_type_id" outlined dense
                                                                multiple :rules=required></v-autocomplete>
                                                        </div>
                                                    </div>
                                                    <v-autocomplete v-model='dataSupplier.phones[index].other_purposes'
                                                        :items=contactMechPhonePurposeType item-text="description"
                                                        item-value="contact_mech_purpose_type_id" outlined dense multiple
                                                        label="Otros proposios" append-outer-icon="mdi-content-save"
                                                        @click:append-outer='storeOtherPhonePursope(index)'
                                                        class='mt-2'></v-autocomplete>
                                                </v-col>
                                            </v-form>
                                            <v-divider inset class='my-4'></v-divider>
                                        </v-row>
                                    </div>
                                    <div v-else class='mt-2'>
                                        <v-alert border="right" colored-border type="error" elevation="2" dense>
                                            Sin teléfonos registrados
                                        </v-alert>
                                    </div>
                                </v-col>
                            </v-col>
                            <v-col sm=2 class='text-center py-0'>
                                <v-divider class='col-sm-1 px-0 mx-0' inset vertical style="height:100%"></v-divider>
                            </v-col>
                            <v-col sm=5 cols="12">
                                <v-col class='text-center primary white--text py-0'>
                                    EMAILS
                                    <v-btn class='mb-1 grey lighten-3' x-small style="top:1px;right:-4rem"
                                        @click="addEmails">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col class='mb-0'>
                                    <div v-if=dataSupplier.emails.length>
                                        <v-row v-for="(email, index) in dataSupplier.emails" :key=index>
                                            <v-form ref="form_email_contact">
                                                <v-col cols=12 class='pb-0'>
                                                    <v-text-field v-model="dataSupplier.emails[index].email" dense outlined
                                                        label="Correo" class='mt-4' :rules=emailValid
                                                        append-icon="mdi-delete-forever"
                                                        @click:append="deleteEmail(dataSupplier.emails[index])"></v-text-field>
                                                </v-col>
                                                <v-col cols=12 class='pt-0'>
                                                    <div><b class='text-subtitle-2'>Propositos:</b></div>
                                                    <div v-if="email.party_contact_mech_purposes.length > 0">
                                                        <div v-for="(purpose, index) in email.party_contact_mech_purposes"
                                                            :key=index>
                                                            <div class='text-caption'
                                                                v-if="purpose.contact_mech_purpose_type_id != ''">
                                                                {{ index + 1 }}) {{ purpose.description }}
                                                                <v-icon
                                                                    @click='deleteEmailPuporse(email, purpose)'>mdi-delete-forever</v-icon>
                                                            </div>
                                                            <div v-else>
                                                                <v-select v-model=purpose.party_contact_mech_purposes
                                                                    :items=contactMechEmailPurposeType
                                                                    item-text="description"
                                                                    item-value="contact_mech_purpose_type_id" outlined dense
                                                                    multiple :rules=required></v-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <v-select v-model='dataSupplier.emails[index].other_purposes'
                                                        v-if="!dataSupplier.emails[index].party_contact_mech_purposes.length || dataSupplier.emails[index].party_contact_mech_purposes[0].description != ''"
                                                        :items=contactMechEmailPurposeType item-text="description"
                                                        item-value="contact_mech_purpose_type_id" label="Otros propositos"
                                                        outlined dense multiple append-outer-icon="mdi-content-save"
                                                        @click:append-outer='storeOtherEmailPursope(index)'
                                                        class='mt-2'></v-select>
                                                </v-col>
                                                <v-divider inset class='my-4'></v-divider>
                                            </v-form>
                                        </v-row>
                                    </div>
                                    <div v-else class='mt-2'>
                                        <v-alert border="right" colored-border type="error" elevation="2" dense>
                                            Sin correos registrados
                                        </v-alert>
                                    </div>
                                </v-col>
                            </v-col>
                        </v-row>
                        <v-col class='text-center'>
                            <v-btn class="text-center" color='default' @click=closeDialog>
                                <v-icon>mdi-cancel</v-icon> Cerrar
                            </v-btn>
                        </v-col>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6' v-if=availableItems.directions>
                    <v-card flat class='my-2 mx-2'>
                        <v-row>
                            <v-col sm=5 cols="12">
                                <v-form ref='from_direction'>
                                    <v-col class='py-0'>
                                        <v-autocomplete v-model='province' :items=provinces item-value='geo_id'
                                            item-text='geo_name' dense label='Seleccione la provincia'
                                            prepend-inner-icon='mdi-map' :rules=required
                                            @change="getGeoData(`${province}/parroquias-provincia`, 'states')"></v-autocomplete>
                                    </v-col>
                                    <v-col class='py-0'>
                                        <v-autocomplete v-model=state :items=states item-value='parroquia_id'
                                            item-text='parroquia' dense label='Seleccione la ciudad'
                                            prepend-inner-icon='mdi-city' return-object :rules=required
                                            @change="getGeoData(`${state.cantonId}/sectores-canton`, 'sector')"></v-autocomplete>
                                    </v-col>
                                    <v-col class='py-0'>
                                        <v-autocomplete v-if=autoCompleteSector v-model=sector :items=sectors
                                            item-value='id' item-text='nombre' dense label='Seleccione el sector'
                                            prepend-inner-icon='mdi-city' :rules=required></v-autocomplete>
                                        <v-text-field v-else v-model=sector dense label='Escriba el sector'
                                            prepend-inner-icon='mdi-city' :rules=required></v-text-field>
                                    </v-col>
                                    <v-col class='py-0'>
                                        <v-text-field v-model=street dense label='Calle principal y número'
                                            prepend-inner-icon='mdi-map-marker-radius' :rules=required></v-text-field>
                                    </v-col>
                                    <v-col class='py-0'>
                                        <v-text-field v-model=street2 dense label='Calle secundaria'
                                            prepend-inner-icon='mdi-map-marker-radius' :rules=required></v-text-field>
                                    </v-col>
                                    <v-col class='pb-0'>
                                        <v-textarea v-model=reference outlined label='Referencias'></v-textarea>
                                    </v-col>
                                    <v-col class='py-0'>
                                        <v-select v-model='directionPurposes' :items=contactMechAddressPurposeType
                                            item-text="description" item-value="contact_mech_purpose_type_id" outlined dense
                                            multiple label="Proposios" :rules=required></v-select>
                                    </v-col>
                                    <v-col class='text-center'>
                                        <v-btn class="text-center" color='primary' @click="storeDirection">
                                            <v-icon>mdi-content-save</v-icon> Guardar
                                        </v-btn>
                                        <v-btn class="text-center" color='default' @click=closeDialog>
                                            <v-icon>mdi-cancel</v-icon> Cerrar
                                        </v-btn>
                                    </v-col>
                                </v-form>
                            </v-col>
                            <v-col sm=1 class='text-center py-0'>
                                <v-divider class='col-sm-1 px-0 mx-0' inset vertical style="height:100%"></v-divider>
                            </v-col>
                            <v-col sm=6 cols="12">
                                <div v-if='dataSupplier.directions.length'>
                                    <div v-for="(direction, index) in dataSupplier.directions" :key=index>
                                        <div><b>{{ index + 1 }}) Dirección:</b></div>
                                        <div class='text-caption'>
                                            <div style='background:white; border:1px solid #9e9e9e; border-radius:5px'>
                                                <div class="row">
                                                    <div class='col-11'>
                                                        <div class=' ml-2'>
                                                            {{ direction.address1 }} {{ direction.address2 }},
                                                            {{ direction.city }}, {{ direction.sector }}
                                                            {{ direction.state_province_geo_id }}
                                                        </div>
                                                    </div>
                                                    <div class="col-1 text-center"
                                                        style='display: flex; align-items: center; position: relative;right: 17px;'>
                                                        <v-icon
                                                            @click='deleteDirection(dataSupplier.directions[index])'>mdi-delete-forever</v-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div><b>Propositos:</b></div>
                                        <div v-if="direction.party_contact_mech_purposes.length > 0">
                                            <div v-for="(purpose, index) in direction.party_contact_mech_purposes"
                                                :key=index>
                                                <div class='text-caption ml-2'>
                                                    {{ index + 1 }}) {{ purpose.description }}
                                                    <v-icon
                                                        @click='deleteDirectionPuporse(direction, purpose)'>mdi-delete-forever</v-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <v-select v-model='dataSupplier.directions[index].other_purposes'
                                            :items=contactMechAddressPurposeType item-text="description"
                                            item-value="contact_mech_purpose_type_id" outlined dense multiple
                                            label="Otros proposios" append-outer-icon="mdi-content-save"
                                            @click:append-outer='storeOtherDirectionPursope(index)' class='mt-2'></v-select>
                                    </div>
                                </div>
                                <div v-else class='mt-2'>
                                    <v-alert border="right" colored-border type="error" elevation="2" dense>
                                        Sin direcciones registradas
                                    </v-alert>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6' v-if=availableItems.preferences>
                    <v-card flat class='my-2 mx-2'>
                        <v-form ref="form_preference">
                            <v-row>
                                <v-col md=4 sm=6 cols=12>
                                    <v-select :items=retentionsIva item-value="deduction_type_id" item-text="description"
                                        v-model=dataSupplier.preferences.ret_iva_id outlined dense label="Retención de IVA"
                                        :rules=required></v-select>
                                </v-col>
                                <v-col md=4 sm=6 cols=12>
                                    <v-select :items=retentionsRent item-value="deduction_type_id" item-text="description"
                                        v-model=dataSupplier.preferences.ret_ir_id outlined dense label="Retención de IR"
                                        :rules=required></v-select>
                                </v-col>
                                <v-col md=4 sm=6 cols=12>
                                    <v-select :items=deadlines item-value="plazo_id" item-text="descripcion"
                                        v-model=dataSupplier.preferences.plazo_id outlined dense
                                        label="Plazo en días"></v-select>
                                </v-col>
                                <v-col md=4 sm=6 cols=12>
                                    <v-text-field v-model=dataSupplier.preferences.monto_credito dense min=1 type="number"
                                        outlined label="Límite de crédito" :rules=minVal></v-text-field>
                                </v-col>
                                <v-col md=4 sm=6 cols=12>
                                    <v-select :items=companyTypes item-value="company_type_id" item-text="description"
                                        v-model=dataSupplier.preferences.company_type_id outlined dense
                                        label="Tipo de empresa"></v-select>
                                </v-col>
                                <v-col md=4 sm=6 cols=12>
                                    <v-text-field v-if=dataSupplier.preferences.contr_esp
                                        v-model=dataSupplier.preferences.cod_contr_esp dense outlined
                                        label="Código de contribuyente especial" :rules=required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md=4 sm=6 cols=12 class='pt-0 pb-8'>
                                    <v-checkbox v-model="dataSupplier.preferences.micro_empresa"
                                        label="Regimen rimpe micro empresa" hide-details
                                        @change='changeMicroEmpresa'></v-checkbox>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 class='pt-0 pb-8'>
                                    <v-checkbox v-model="dataSupplier.preferences.emprendedor"
                                        label="Regimen rimpe emprendedor" hide-details
                                        @change='changeEmprendedor'></v-checkbox>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 class='pt-0 pb-8'>
                                    <v-checkbox v-model="dataSupplier.preferences.obligado_cont"
                                        label="Obligado a llevar contabilidad" hide-details></v-checkbox>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 class='pt-0 pb-8'>
                                    <v-checkbox v-model="dataSupplier.preferences.contr_esp" label="Contribuyente especial"
                                        hide-details></v-checkbox>
                                </v-col>
                            </v-row>
                            <v-col class='text-center'>
                                <v-btn class="text-center" color='primary' @click="storePreferences">
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn class="text-center" color='default' @click=closeDialog>
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6' v-if=availableItems.paymentTypes>
                    <v-card flat class='my-2 mx-2'>
                        <v-form ref="form_payment_type">
                            <v-row>
                                <v-col md=4 sm=6 cols=12>
                                    <v-select v-model=dataSupplier.paymentType.default_pay_meth :items=paymentMethodTypes
                                        item-value="payment_method_type_id" item-text="description" outlined dense
                                        label="Forma de pago" :rules=required></v-select>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 v-if="dataSupplier.paymentType.default_pay_meth == 'FIN_ACCOUNT'">
                                    <v-select v-model=dataSupplier.paymentType.fin_account_id :items=finAccounts
                                        item-value="fin_account_id" item-text="fin_account_name" outlined dense
                                        label="Cuentas bancarias a debitar" :rules=required></v-select>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 v-if="dataSupplier.paymentType.default_pay_meth == 'EFT_ACCOUNT'">
                                    <v-text-field v-model=dataSupplier.paymentType.name_on_account dense outlined
                                        label="Nombre de la cuenta" :rules=required></v-text-field>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 v-if="dataSupplier.paymentType.default_pay_meth == 'EFT_ACCOUNT'">
                                    <v-text-field v-model=dataSupplier.paymentType.company_name_on_account dense outlined
                                        label="Nombre de compañia en cuenta" :rules=required></v-text-field>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 v-if="dataSupplier.paymentType.default_pay_meth == 'EFT_ACCOUNT'">
                                    <v-select v-model=dataSupplier.paymentType.identification_type_id
                                        :items=typeIdentifications item-text="description"
                                        item-value="party_identification_type_id" outlined dense
                                        label="Tipo de identificación en cuenta" :rules=required></v-select>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 v-if="dataSupplier.paymentType.default_pay_meth == 'EFT_ACCOUNT'">
                                    <v-text-field v-model=dataSupplier.paymentType.num_identificacion dense outlined
                                        label="Identificación de compañia en cuenta" :rules=required></v-text-field>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 v-if="dataSupplier.paymentType.default_pay_meth == 'EFT_ACCOUNT'">
                                    <v-autocomplete v-model=dataSupplier.paymentType.codigo_banco :items=banks
                                        item-value=enum_id item-text=description outlined dense label="Banco"
                                        :rules=required></v-autocomplete>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 v-if="dataSupplier.paymentType.default_pay_meth == 'EFT_ACCOUNT'">
                                    <v-select v-model=dataSupplier.paymentType.account_type :items=typeAccounts
                                        item-text="type" item-value="type" outlined dense label="Tipo de cuenta"
                                        :rules=required></v-select>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 v-if="dataSupplier.paymentType.default_pay_meth == 'EFT_ACCOUNT'">
                                    <v-text-field v-model=dataSupplier.paymentType.account_number dense outlined
                                        label="Número de cuenta" :rules=required></v-text-field>
                                </v-col>
                                <v-col md=4 sm=6 cols=12 v-if="dataSupplier.paymentType.default_pay_meth == 'EFT_ACCOUNT'">
                                    <v-text-field v-model=dataSupplier.paymentType.description dense outlined
                                        label="Descripción" :rules=required></v-text-field>
                                </v-col>
                            </v-row>
                            <v-col class='text-center'>
                                <v-btn class="text-center" color='primary' @click="storePaymentType">
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn class="text-center" color='default' @click=closeDialog>
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6' v-if=availableItems.accountsToPay>
                    <v-card flat class='my-2 mx-2'>
                        <v-data-table :headers="headersAccountsToPay" :items="dataTableAccountsToPay" class="elevation-1"
                            :disable-sort="$vuetify.breakpoint.name == 'xs'" no-data-text="Sin cuentas por pagar" dense>
                            <template v-slot:item.invoice_id="{ item }">
                                <a target="_blank"
                                    :href="`${urlExternal}/compras/control/invoiceOverview?invoiceId=${item.invoice_id}`">
                                    {{ item.invoice_id }}
                                </a>
                            </template>
                        </v-data-table>
                        <v-col class='text-center'>
                            <v-btn class="text-center" color='default' @click=closeDialog>
                                <v-icon>mdi-cancel</v-icon> Cerrar
                            </v-btn>
                        </v-col>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6' v-if=availableItems.uploadFiles>
                    <v-card flat class='my-2 mx-2'>
                        <v-form ref="form_upload_files">
                            <v-row>
                                <v-col md=4 sm=6 cols=12 v-if=requiredCedula>
                                    <v-file-input accept=".png, .jpg, .pdf" hide-details="" label="Cédula" outlined dense
                                        clearable prepend-inner-icon="mdi-file-send" prepend-icon=""
                                        @change="setfiles($event, 'cedula')">
                                        <template v-slot:append>
                                            <v-icon v-if="!!fileCedula" @click="openFile(fileCedula)">
                                                mdi-file-image
                                            </v-icon>
                                        </template>
                                    </v-file-input>
                                </v-col>
                                <v-col md=4 sm=6 cols=12>
                                    <v-file-input accept=".png, .jpg, .pdf" hide-details="" label="R.U.C" outlined dense
                                        clearable prepend-inner-icon="mdi-file-send" prepend-icon=""
                                        @change="setfiles($event, 'ruc')">
                                        <template v-slot:append>
                                            <v-icon v-if="!!fileRuc" @click="openFile(fileRuc)">
                                                mdi-file-image
                                            </v-icon>
                                        </template>
                                    </v-file-input>

                                </v-col>
                                <v-col md=4 sm=6 cols=12>
                                    <v-file-input accept=".png, .jpg, .pdf" hide-details="" label="Representante legal"
                                        outlined dense clearable prepend-inner-icon="mdi-file-send" prepend-icon=""
                                        @change="setfiles($event, 'rep_legal')">
                                        <template v-slot:append>
                                            <v-icon v-if="!!fileRepLegal" @click="openFile(fileRepLegal)">
                                                mdi-file-image
                                            </v-icon>
                                        </template>
                                    </v-file-input>
                                </v-col>
                                <v-col md=4 sm=6 cols=12>
                                    <v-file-input accept=".png, .jpg, .pdf" hide-details="" label="Certificado bancario"
                                        outlined dense clearable prepend-inner-icon="mdi-file-send" prepend-icon=""
                                        @change="setfiles($event, 'cert_bank')">
                                        <template v-slot:append>
                                            <v-icon v-if="!!fileCertBank" @click="openFile(fileCertBank)">
                                                mdi-file-image
                                            </v-icon>
                                        </template>
                                    </v-file-input>
                                </v-col>
                                <v-col md=4 sm=6 cols=12>
                                    <v-file-input accept=".png, .jpg, .pdf" hide-details="" label="Contrato" outlined dense
                                        clearable prepend-inner-icon="mdi-file-send" prepend-icon=""
                                        @change="setfiles($event, 'contract')">
                                        <template v-slot:append>
                                            <v-icon v-if="!!fileContract" @click="openFile(fileContract)">
                                                mdi-file-image
                                            </v-icon>
                                        </template>
                                    </v-file-input>
                                </v-col>
                                <v-col class='text-center' cols=12>
                                    <v-btn class="text-center" color='primary' @click="uploadFiles">
                                        <v-icon>mdi-content-save</v-icon> Guardar
                                    </v-btn>
                                    <v-btn class="text-center" color='default' @click=closeDialog>
                                        <v-icon>mdi-cancel</v-icon> Cerrar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6' v-if=availableItems.workTeam>
                    <v-card flat class='my-2 mx-2'>
                        <v-form ref='form_work_group'>
                            <v-row>
                                <v-col md=6>
                                    <v-col class='text-center primary white--text py-0'>DATOS</v-col>
                                    <v-col class='pb-0'>
                                        <v-row>
                                            <v-col class='pt-6'>
                                                <v-text-field v-model="dataSupplier.first_name" dense
                                                    label="Nombre del grupo" :rules=required></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class='pt-0'>
                                        <v-text-field v-model="dataSupplier.comments" dense
                                            label="Observaciones"></v-text-field>
                                    </v-col>
                                </v-col>
                                <!-- <v-col md=4>
                                    <v-col class='text-center primary white--text py-0'>
                                        ROLES
                                    </v-col>
                                    <v-col>
                                        <div v-if=dataSupplier.rols.length>
                                            <div 
                                                v-for="(rol, index) in dataSupplier.rols" 
                                                :key=index
                                            > 
                                                <div 
                                                    class='text-caption' 
                                                    v-if="rol.role_type_id!=''"
                                                >
                                                    {{index+1}})  {{rol.description}}

                                                    <v-icon
                                                         @click="deleteRol(dataSupplier.rols[index])"
                                                         v-if="rol.role_type_id!='WORK_GROUP'"
                                                    >
                                                        mdi-delete-forever
                                                    </v-icon>
                                                </div>
                                                <div>
                                               
                                                </div>  
                                            </div>                                            
                                        </div>
                                        <div v-else class='mt-2'>
                                            <v-alert
                                                border="right"
                                                colored-border
                                                type="error"
                                                elevation="2"
                                                dense
                                                >
                                                Sin roles registrados
                                            </v-alert>
                                        </div>
                                        <div class="mt-4">
                                            <v-autocomplete 
                                                v-model=dataSupplier.others_rols
                                                :items=rolTypesWG
                                                item-text="description"
                                                item-value="role_type_id"
                                                label="Otros roles"
                                                outlined
                                                dense
                                                multiple
                                                class='mt-2'
                                            ></v-autocomplete>
                                        </div>
                                    </v-col>
                                </v-col> -->
                                <v-col md=6>
                                    <v-col class='text-center primary white--text py-0'>
                                        PARTICIPANTES
                                    </v-col>
                                    <v-col>
                                        <div v-if=dataSupplier.usersGroup.length>
                                            <div v-for="(user, index) in dataSupplier.usersGroup" :key=index>
                                                <div class='text-caption text-uppercase'>
                                                    {{ index + 1 }}) {{ user.name }}
                                                    <v-icon @click="deleteUserWorkGroup(dataSupplier.usersGroup[index])">
                                                        mdi-delete-forever
                                                    </v-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class='mt-2'>
                                            <v-alert border="right" colored-border type="error" elevation="2" dense>
                                                Sin usuario registrados
                                            </v-alert>
                                        </div>
                                        <div class="mt-4">
                                            <v-autocomplete v-model=dataSupplier.other_users :items=users item-text="name"
                                                item-value="party_id" label="Otros participantes" outlined dense multiple
                                                class='mt-2'></v-autocomplete>
                                        </div>
                                    </v-col>
                                </v-col>
                            </v-row>
                            <v-col class='text-center'>
                                <v-btn class="text-center" color='primary' @click="storeWorkGroup" :loading=loadingBtn>
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn class="text-center" color='default' @click=closeDialog>
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-tab-item>
                <v-tab-item class='mt-6' v-if=availableItems.assignWorkEfort>
                    <div class='my-4'>
                        <v-btn color='primary' @click='addWorkEffotType'>
                            <v-icon>mdi-plus-circle-outline</v-icon> Agregar
                        </v-btn>
                    </div>
                    <v-form ref='form_Work_effot_type'>
                        <div v-for="(workEffortType, index) in dataSupplier.workEffortPurposeType" :key=index>
                            <v-row>
                                <v-col cols=12 sm=3>
                                    <v-text-field :value="dataSupplier.first_name + ' ' + dataSupplier.last_name" dense
                                        outlined label="Grupo de trabajo" readonly></v-text-field>
                                </v-col>
                                <v-col cols=12 sm=4>
                                    <v-autocomplete v-model="dataSupplier.workEffortPurposeType[index].work_effort_type_id"
                                        :items=workEffortsTypes item-value="work_effort_type_id" item-text="description"
                                        outlined dense label='Tipo de tarea' :rules=required></v-autocomplete>
                                </v-col>
                                <v-col cols=10 sm=4>
                                    <v-autocomplete
                                        v-model="dataSupplier.workEffortPurposeType[index].work_effort_purpose_type_id"
                                        :items=workEffortPurposeType item-value="work_effort_purpose_type_id"
                                        item-text="description" outlined dense label='Seleccione el proposito de la tarea'
                                        :rules=required></v-autocomplete>
                                </v-col>
                                <v-col cols=1>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn fab small color='error' v-bind="attrs" v-on="on"
                                                @click="deleteWorkEffotType(dataSupplier.workEffortPurposeType[index])">
                                                <v-icon>mdi-delete-forever</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Eliminar asignación</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                            <v-divider inset class='my-4'></v-divider>
                        </div>
                        <v-row>
                            <v-col class='text-center'>
                                <v-btn class='primary' @click=storeWorkEffotType>
                                    <v-icon>mdi-content-save</v-icon> Guardar
                                </v-btn>
                                <v-btn class="text-center" color='default' @click=closeDialog>
                                    <v-icon>mdi-cancel</v-icon> Cerrar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-dialog>
</template>

<script>

import { mapState, mapMutations, mapActions } from 'vuex'
import Vue from 'vue'
import axios from 'axios'

export default {
    name: "StorePersonGroupComponent",
    props: {
        availableItems: {
            type: Object,
            default: () => { }
        },
        dataParty: {
            type: Object,
            default: () => { }
        },
        dialog: {
            type: Boolean,
            default: false
        },
        roleDefault: {
            type: String,
            dafult: ''
        }
    },
    data: function () {
        return {
            typeIdentifications: [],
            contactMechPhonePurposeType: [],
            contactMechEmailPurposeType: [],
            contactMechAddressPurposeType: [],
            partyClassificationsGroups: [],
            paymentMethodTypes: [],
            directionPurposes: [],
            retentionsIva: [],
            retentionsRent: [],
            deadlines: [],
            companyTypes: [],
            workEffortPurposeType: [],
            rolTypesWG: [],
            workEffortsTypes: [],
            banks: [],
            rolTypes: [],
            provinces: [],
            sectors: [],
            states: [],
            users: [],
            loadedFiles: [],
            dataTableAccountsToPay: [],
            finAccounts: [],
            headersAccountsToPay: [
                { text: 'ID Documento', align: 'start', value: 'invoice_id' },
                { text: 'Documento', align: 'start', value: 'invoice_number' },
                { text: 'Fecha Documento', align: 'start', value: 'invoice_date' },
                { text: 'Fecha Vencimiento', align: 'start', value: 'fecha_venc' },
                { text: 'Dias Vencidos', align: 'start', value: 'dias' },
                { text: 'Total', align: 'start', value: 'total_fa' },
                { text: 'Pagado', align: 'start', value: 'pagado' },
                { text: 'Pendiente', align: 'start', value: 'saldo' }
            ],
            status: [
                { status: 'Y', description: 'Habilitado' },
                { status: 'N', description: 'Deshabilitado' }
            ],
            tab: null,
            province: null,
            street: null,
            street2: null,
            direction: null,
            province: null,
            state: null,
            sector: null,
            reference: null,
            autoCompleteSector: false,
            typeInputPass: false,
            typeAccounts: [{ type: 'Ahorros' }, { type: 'Corriente' }],
            dataSupplier: {
                first_name: '',
                last_name: '',
                bussines_name: '',
                user_login_id: '',
                old_user_login_id: '',
                status: '',
                comments: '',
                partyType: '',
                party_classification_group_id: '',
                prom_gasto: '',
                password: '',
                others_rols: [],
                contacts: [
                    {
                        party_id_to: '',
                        party_id_from: '',
                        role_type_id_from: '',
                        role_type_id_to: '',
                        first_name: '',
                        last_name: '',
                        contact_mech_id_phone: '',
                        number: '',
                        contact_mech_id_mail: '',
                        info_string: '',
                        position_title: ''
                    }
                ],
                partyIdentifications: [
                    {
                        old_id_value: '',
                        party_identification_type_id: '',
                        id_value: '',
                        party_id: ''
                    }
                ],
                phones: [
                    {
                        contact_number: '',
                        contact_mech_id: '',
                        other_purposes: [],
                        party_contact_mech_purposes: [
                            {
                                contact_mech_purpose_type_id: '',
                                description: ''
                            }
                        ]
                    }
                ],
                emails: [
                    {
                        email: '',
                        contact_mech_id: '',
                        other_purposes: [],
                        party_contact_mech_purposes: [
                            {
                                contact_mech_purpose_type_id: '',
                                description: ''
                            }
                        ]
                    }
                ],
                directions: [
                    {
                        state_province_geo_id: '',
                        state: '',
                        sector: '',
                        street: '',
                        street2: '',
                        reference: '',
                        contact_mech_id: '',
                        party_contact_mech_purposes: [
                            {
                                contact_mech_purpose_type_id: '',
                                description: ''
                            }
                        ]
                    }
                ],
                rols: [
                    {
                        role_type_id: '',
                        description: '',
                        party_id: '',
                    }
                ],
                preferences: {
                    monto_credito: 0,
                    ret_iva_id: '',
                    ret_ir_id: '',
                    plazo_id: '',
                    company_type_id: '',
                    obligado_cont: '',
                    micro_empresa: '',
                    emprendedor: '',
                    contr_esp: '',
                    cod_contr_esp: '',
                },
                workEffortPurposeType: [
                    {
                        party_id: '',
                        old_work_effort_type_id: '',
                        old_work_effort_purpose_type_id: '',
                        work_effort_type_id: '',
                        work_effort_purpose_type_id: ''
                    }
                ],
                usersGroup: [],
                other_users: [],
                paymentType: {
                    default_pay_meth: '',
                    payment_method_id: '',
                    payment_method_type_id: '',
                    description: '',
                    account_type: '',
                    codigo_banco: '',
                    account_number: '',
                    name_on_account: '',
                    company_name_on_account: '',
                    num_identificacion: '',
                    fin_account_id: '',
                    identification_type_id: ''
                },
                files: {
                    cedula: '',
                    ruc: '',
                    rep_legal: '',
                    cert_bank: '',
                    contract: ''
                },
            },
            required: [v => !!v || 'El campo es requerido'],
            minVal: [
                v => v >= 0 || 'El valor debe ser 0 o mayor',
                v => !!v || 'El campo es requerido'
            ],
            emailValid: [
                v => !!v || 'Debe escribir un correo electrónico',
                v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Debe escribir un correo válido'
            ]
        }
    },
    computed: {

        ...mapState('master', ['loadingBtn', 'urlExternal', 'token', 'url', 'tenantId']),

        ...mapState('purchases', ['urlFilesParty']),

        requiredCedula() {
            let required = false
            this.dataSupplier.partyIdentifications.forEach(obj => {
                let arr = obj.id_value.split('')
                if (arr[2] != '9') { //CEDULA
                    required = true
                    return
                }
            })
            return required
        },

        fileCedula() {
            return this.loadedFiles.find(e => e.description == 'cedula')
        },

        fileCertBank() {
            return this.loadedFiles.find(e => e.description == "cert_bank")
        },

        fileContract() {
            return this.loadedFiles.find(e => e.description == 'contract')
        },

        fileRepLegal() {
            return this.loadedFiles.find(e => e.description == 'rep_legal')
        },

        fileRuc() {
            return this.loadedFiles.find(e => e.description == 'ruc')
        },

        path() {
            return this.$route.fullPath.split('/')[1]
        }

    },
    methods: {

        ...mapMutations('master', ['setUrl', 'setLoadingBtn']),

        ...mapActions('master', ['requestApi', 'alertNotification']),

        getGeoData(url, input) {

            this.setUrl(`geo/${url}`)
            this.requestApi({
                method: 'GET',
                data: {}
            }).then(res => {
                if (input === 'provinces') {
                    this.provinces = res.data.detail
                } else if (input === 'states') {
                    this.states = res.data.detail
                } else {
                    this.sectors = res.data.detail
                    this.autoCompleteSector = res.data.detail.length
                }
            })

        },

        storeDirection() {

            console.log(this.dataSupplier)

            if (!this.$refs.from_direction.validate())
                return

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    province: this.province,
                    street: this.street,
                    street2: this.street2,
                    city: this.state.parroquia,
                    sector: this.sector,
                    reference: this.reference,
                    directionPurposes: this.directionPurposes,
                    partyId: this.dataSupplier.party_id,
                    typeStore: 'storeDirection'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                this.dataSupplier.directions.unshift({
                    state: this.state.parroquia,
                    sector: this.sector,
                    address1: this.street,
                    address2: this.street2,
                    reference: this.reference,
                    state_province_geo_id: this.province,
                    contact_mech_id: res.data.res.contact_mech_id,
                    party_id: this.dataSupplier.party_id,
                    party_contact_mech_purposes: this.contactMechAddressPurposeType.filter(
                        e => this.directionPurposes.includes(e.contact_mech_purpose_type_id)
                    )
                })
                this.directionPurposes = []
                console.log(this.dataSupplier)


            }).then(() => {
                this.setLoadingBtn(false)
            })

        },

        storeGeneralData() {
            
            let newParty = typeof this.dataSupplier.party_id == 'undefined'

            if (!this.$refs.form_general_data.validate())
                return

            const {
                bussines_name,
                comments,
                partyIdentifications,
                first_name,
                last_name,
                status,
                party_id,
                partyType,
                party_classification_group_id,
                prom_gasto,
                rols,
                others_rols,
                user_login_id,
                password,
                old_user_login_id
            } = this.dataSupplier

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    roleDefault: this.roleDefault,
                    bussines_name,
                    comments,
                    partyIdentifications,
                    first_name,
                    last_name,
                    status,
                    party_id,
                    partyType,
                    party_classification_group_id,
                    prom_gasto,
                    rols,
                    others_rols,
                    user_login_id,
                    password,
                    old_user_login_id,
                    typeStore: 'generalData',
                    pathView: this.$route.fullPath
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                others_rols.forEach(role_type_id => {

                    let rol = this.rolTypes.find(e => e.role_type_id == role_type_id)
                    rol.party_id = party_id

                    this.dataSupplier.rols.push(rol)

                })
                this.dataSupplier.party_id = res.data.res.partyId
                this.dataSupplier.others_rols = []
                this.$emit('savedNewParty', {
                    partyId: res.data.res.partyId,
                    newParty: newParty
                })

            }).then(() => {
                this.setLoadingBtn(false)
            })

        },

        closeDialog() {
            this.$emit('closeDialog')
        },

        addIdentification() {
            if (this.dataSupplier.partyIdentifications.length + 1 <= this.typeIdentifications.length) {
                this.dataSupplier.partyIdentifications.unshift({
                    party_identification_type_id: '',
                    id_value: '',
                    party_id: ''
                })
            }
        },

        addContacts() {
            this.dataSupplier.contacts.unshift({
                party_id_to: '',
                party_id_from: '',
                role_type_id_from: '',
                role_type_id_to: '',
                first_name: '',
                last_name: '',
                contact_mech_id_phone: '',
                number: '',
                contact_mech_id_mail: '',
                info_string: ''
            })
        },

        deleteContact(data) {
            console.log(data)
            let index = this.dataSupplier.contacts.indexOf(data)

            if (data.party_id_from == '') {

                this.dataSupplier.contacts.splice(index, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar este contacto?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            party_id_from,
                            party_id_to,
                            role_type_id_from,
                            role_type_id_to
                        } = data

                        this.setUrl('lista-partys')
                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                party_id_to,
                                party_id_from,
                                role_type_id_from,
                                role_type_id_to,
                                typeDelete: 'deleteContact'
                            }
                        }).then(res => {

                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })

                            this.dataSupplier.contacts.splice(index, 1)

                        })

                    }
                })

            }
        },

        deleteIdentification(data) {
            console.log(data)

            let obj = this.dataSupplier.partyIdentifications.find(e => e.id_value == data.id_value)
            let index = this.dataSupplier.partyIdentifications.indexOf(obj)

            if (data.party_id == '') {

                this.dataSupplier.partyIdentifications.splice(index, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar esta identificación?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            party_identification_type_id,
                            party_id
                        } = data

                        this.setUrl('lista-partys')

                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                party_identification_type_id,
                                party_id,
                                typeDelete: 'deleteIdentification'
                            }
                        }).then(res => {
                            console.log(res)

                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })
                            this.dataSupplier.partyIdentifications.splice(index, 1)

                        })

                    }
                })

            }

        },

        addPhones() {
            this.dataSupplier.phones.unshift({
                contact_number: '',
                contact_mech_id: '',
                other_purposes: [],
                party_contact_mech_purposes: [
                    {
                        contact_mech_purpose_type_id: "PHONE_SHIP_ORIG",
                        description: "Shipping Origin Phone Number"
                    },
                    {
                        contact_mech_purpose_type_id: "PHONE_BILLING",
                        description: "Billing (AP) Phone Number"
                    },
                    {
                        contact_mech_purpose_type_id: "PRIMARY_PHONE",
                        description: "Primary Phone Number"
                    }
                ]
            })
        },

        addEmails() {
            this.dataSupplier.emails.unshift({
                email: '',
                contact_mech_id: '',
                other_purposes: [],
                party_contact_mech_purposes: [
                    {
                        contact_mech_purpose_type_id: "BILLING_EMAIL",
                        description: "Billing (AP) Email"
                    }
                ]
            })
        },

        deleteEmailPuporse(email, purpose) {
            console.log(email, purpose, this.dataSupplier)

            let obj = this.dataSupplier.emails.find(e => e.contact_mech_id == email.contact_mech_id)
            let index = this.dataSupplier.emails.indexOf(obj)

            let obj2 = this.dataSupplier.emails[index].party_contact_mech_purposes.find(e => e.contact_mech_purpose_type_id == purpose.contact_mech_purpose_type_id)
            let index2 = this.dataSupplier.emails[index].party_contact_mech_purposes.indexOf(obj2)

            if (email.contact_mech_id == '') {

                this.dataSupplier.emails[index].party_contact_mech_purposes.splice(index2, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar este proposito?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            contact_mech_id,
                            party_id
                        } = email

                        const { contact_mech_purpose_type_id } = purpose

                        this.setUrl('lista-partys')

                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                contact_mech_purpose_type_id,
                                contact_mech_id,
                                party_id,
                                typeDelete: 'deletePursope'
                            }
                        }).then(res => {
                            console.log(res)

                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })
                            this.dataSupplier.emails[index].party_contact_mech_purposes.splice(index2, 1)

                        })

                    }
                })

            }



        },

        deleteDirectionPuporse(direction, purpose) {


            let obj = this.dataSupplier.directions.find(e => e.contact_mech_id == direction.contact_mech_id)
            let index = this.dataSupplier.directions.indexOf(obj)

            let obj2 = this.dataSupplier.directions[index].party_contact_mech_purposes.find(e => e.contact_mech_purpose_type_id == purpose.contact_mech_purpose_type_id)
            let index2 = this.dataSupplier.directions[index].party_contact_mech_purposes.indexOf(obj2)

            if (direction.contact_mech_id == '') {

                this.dataSupplier.directions[index].party_contact_mech_purposes.splice(index2, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar este proposito?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            contact_mech_id,
                            party_id
                        } = direction

                        const { contact_mech_purpose_type_id } = purpose

                        this.setUrl('lista-partys')

                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                contact_mech_purpose_type_id,
                                contact_mech_id,
                                party_id,
                                typeDelete: 'deletePursope'
                            }
                        }).then(res => {
                            console.log(res)

                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })
                            this.dataSupplier.directions[index].party_contact_mech_purposes.splice(index2, 1)

                        })

                    }
                })

            }
        },

        deletePhonePuporse(phone, purpose) {

            console.log(phone, purpose)

            let obj = this.dataSupplier.phones.find(e => e.contact_mech_id == phone.contact_mech_id)
            let index = this.dataSupplier.phones.indexOf(obj)

            let obj2 = this.dataSupplier.phones[index].party_contact_mech_purposes.find(e => e.contact_mech_purpose_type_id == purpose.contact_mech_purpose_type_id)
            let index2 = this.dataSupplier.phones[index].party_contact_mech_purposes.indexOf(obj2)

            if (phone.contact_mech_id == '') {

                this.dataSupplier.phones[index].party_contact_mech_purposes.splice(index2, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar este proposito?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            contact_mech_id,
                            party_id
                        } = phone

                        const { contact_mech_purpose_type_id } = purpose

                        this.setUrl('lista-partys')

                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                contact_mech_purpose_type_id,
                                contact_mech_id,
                                party_id,
                                typeDelete: 'deletePursope'
                            }
                        }).then(res => {
                            console.log(res)

                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })
                            this.dataSupplier.phones[index].party_contact_mech_purposes.splice(index2, 1)

                        })

                    }
                })

            }


        },

        deletePhone(data) {

            let index = this.dataSupplier.phones.indexOf(data)

            if (data.contact_mech_id == '') {

                this.dataSupplier.phones.splice(index, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar este teléfono?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            party_id,
                            contact_mech_id
                        } = data

                        this.setUrl('lista-partys')
                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                party_id,
                                contact_mech_id,
                                typeDelete: 'deletePhone'
                            }
                        }).then(res => {
                            console.log(res)
                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })
                            console.log('index' + index)
                            this.dataSupplier.phones.splice(index, 1)

                        })

                    }
                })

            }

        },

        deleteRol(data) {
            console.log(data, this.dataSupplier)
            let index = this.dataSupplier.rols.indexOf(data)

            Vue.swal({
                html: "Está seguro de eliminar este rol?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {
                if (result.isConfirmed) {
                    const {
                        role_type_id,
                        party_id
                    } = data

                    this.setUrl('lista-partys')

                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            role_type_id,
                            party_id,
                            typeDelete: 'deleteRol'
                        }
                    }).then(res => {
                        console.log(res)

                        this.alertNotification({
                            param: {
                                html: res.data.detail
                            }
                        })
                        this.dataSupplier.rols.splice(index, 1)


                    }).then(() => {



                    })

                }
            })

        },

        deleteEmail(data) {

            let index = this.dataSupplier.emails.indexOf(data)

            if (data.contact_mech_id == '') {

                this.dataSupplier.emails.splice(index, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar este correo?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            party_id,
                            contact_mech_id
                        } = data

                        this.setUrl('lista-partys')
                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                party_id,
                                contact_mech_id,
                                typeDelete: 'deleteEmail'
                            }
                        }).then(res => {
                            console.log(res)
                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })
                            this.dataSupplier.emails.splice(index, 1)

                        })

                    }
                })

            }


        },

        deleteDirection(data) {
            console.log(data)

            Vue.swal({
                html: "Está seguro de eliminar esta dirección?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {
                if (result.isConfirmed) {

                    const {
                        party_id,
                        contact_mech_id
                    } = data

                    this.setUrl('lista-partys')
                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            party_id,
                            contact_mech_id,
                            typeDelete: 'deleteDirection'
                        }
                    }).then(res => {
                        console.log(res)
                        this.alertNotification({
                            param: {
                                html: res.data.detail
                            }
                        })

                        let index = this.dataSupplier.directions.indexOf(data)
                        this.dataSupplier.directions.splice(index, 1)

                    })

                }
            })

        },

        storeContacts() {

            if (!this.$refs.form_contacts_data.validate())
                return

            const {
                contacts
            } = this.dataSupplier

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    new_role_type_id_to: 'SUPPLIER',
                    party_id: this.dataSupplier.party_id,
                    contacts,
                    typeStore: 'storeContacts'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).then(() => {
                this.setLoadingBtn(false)
            })
        },

        storeOtherPhonePursope(index) {

            //console.log(this.dataSupplier.phones[index],this.dataSupplier)

            if (!this.$refs.form_phone_contact[index].validate())
                return false

            const {
                contact_mech_id,
                contact_mech_type_id,
                contact_number,
                other_purposes,
                party_contact_mech_purposes
            } = this.dataSupplier.phones[index]

            const { party_id } = this.dataSupplier

            this.setUrl('lista-partys')
            this.requestApi({
                method: 'POST',
                data: {
                    contact_mech_id,
                    contact_mech_type_id,
                    contact_number,
                    other_purposes,
                    party_contact_mech_purposes,
                    party_id,
                    typeStore: 'phonesData'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                other_purposes.forEach(contact_mech_purpose_type_id => {
                    this.dataSupplier.phones[index].party_contact_mech_purposes.push(
                        this.contactMechPhonePurposeType.find(e => e.contact_mech_purpose_type_id == contact_mech_purpose_type_id)
                    )
                })
                this.dataSupplier.phones[index].contact_mech_id = res.data.res.contact_mech_id
                this.dataSupplier.phones[index].other_purposes = []

            })

        },

        storeOtherDirectionPursope(index) {
            console.log(this.dataSupplier.directions[index])

            const {
                contact_mech_id,
                other_purposes,
                party_contact_mech_purposes,
                party_id
            } = this.dataSupplier.directions[index]

            this.setUrl('lista-partys')
            this.requestApi({
                method: 'POST',
                data: {
                    contact_mech_id,
                    other_purposes,
                    party_contact_mech_purposes,
                    party_id,
                    typeStore: 'purposeDirection'
                }
            }).then(res => {
                console.log(res)
                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                other_purposes.forEach(contact_mech_purpose_type_id => {
                    this.dataSupplier.directions[index].party_contact_mech_purposes.push(
                        this.contactMechAddressPurposeType.find(e => e.contact_mech_purpose_type_id == contact_mech_purpose_type_id)
                    )
                })
                this.dataSupplier.directions[index].contact_mech_id = res.data.res.contact_mech_id
                this.dataSupplier.directions[index].other_purposes = []

            })

        },

        storeOtherEmailPursope(index) {
            console.log(this.dataSupplier.emails[index], index)

            if (!this.$refs.form_email_contact[index].validate())
                return false

            const {
                contact_mech_id,
                email,
                other_purposes,
                party_contact_mech_purposes,
            } = this.dataSupplier.emails[index]

            const { party_id } = this.dataSupplier

            this.setUrl('lista-partys')
            this.requestApi({
                method: 'POST',
                data: {
                    contact_mech_id,
                    email,
                    other_purposes,
                    party_contact_mech_purposes,
                    party_id,
                    typeStore: 'emailData'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                other_purposes.forEach(contact_mech_purpose_type_id => {
                    this.dataSupplier.emails[index].party_contact_mech_purposes.push(
                        this.contactMechEmailPurposeType.find(e => e.contact_mech_purpose_type_id == contact_mech_purpose_type_id)
                    )
                })
                this.dataSupplier.emails[index].contact_mech_id = res.data.res.contact_mech_id
                this.dataSupplier.emails[index].other_purposes = []

            })

        },

        storePreferences() {

            if (!this.$refs.form_preference.validate())
                return false

            let {
                party_id,
                preferences
            } = this.dataSupplier

            if (!this.dataSupplier.preferences.contr_esp) preferences.cod_contr_esp = ''


            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    ...preferences,
                    party_id,
                    typeStore: 'storePreferences'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).then(() => {
                this.setLoadingBtn(false)
            })

        },

        setfiles(event, file) {
            switch (file) {
                case 'cedula':
                    this.dataSupplier.files.cedula = event
                    break;
                case 'ruc':
                    this.dataSupplier.files.ruc = event
                    break;
                case 'rep_legal':
                    this.dataSupplier.files.rep_legal = event
                    break;
                case 'cert_bank':
                    this.dataSupplier.files.cert_bank = event
                    break;
                case 'contract':
                    this.dataSupplier.files.contract = event
                    break;
            }
        },

        storePaymentType() {

            if (!this.$refs.form_payment_type.validate())
                return false

            const {
                party_id,
                paymentType
            } = this.dataSupplier

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    ...paymentType,
                    party_id,
                    typeStore: 'storePaymentType'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).then(() => {
                this.setLoadingBtn(false)
            })

        },

        uploadFiles() {

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            let formData = new FormData()

            formData.append('tenantId', this.tenantId)
            formData.append('typeStore', 'storePartyContent')
            formData.append('url_party_files', this.urlFilesParty)
            formData.append('party_id', this.dataSupplier.party_id)
            formData.append('cedula', this.dataSupplier.files.cedula)
            formData.append('ruc', this.dataSupplier.files.ruc)
            formData.append('rep_legal', this.dataSupplier.files.rep_legal)
            formData.append('cert_bank', this.dataSupplier.files.cert_bank)
            formData.append('contract', this.dataSupplier.files.contract)

            axios.post(
                this.url,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + this.token
                    }
                }
            ).then(res => {

                this.setLoadingBtn(false)

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }

                })

                this.$emit('savedNewParty', {
                    partyId: this.dataSupplier.party_id,
                    newParty: true
                })

            }).catch(err => {

                console.log(err)

                this.setLoadingBtn(false)

                params = {
                    title: 'Error!',
                    html: err.data.detail,
                    icon: 'error',
                    confirmButtonColor: '#d60400',
                    toast: false,
                    grow: false,
                    position: 'center',
                    confirmButtonText: 'Cerrar'
                }

                Vue.swal({ ...params });
            })

        },

        openFile(obj) {
            let win = window.open()
            let base64URL = `data:${obj.mime_type_id};base64,${obj.base64}`
            win.document.write('<iframe src="' + base64URL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
        },

        storeWorkGroup() {

            console.log(this.dataSupplier)

            let newParty = typeof this.dataSupplier.party_id == 'undefined'

            if (!this.$refs.form_work_group.validate())
                return false

            let {
                comments,
                first_name,
                rols,
                party_id,
                others_rols,
                other_users
            } = this.dataSupplier

            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    comments,
                    first_name,
                    rols,
                    others_rols,
                    party_id,
                    other_users,
                    typeStore: 'storeWorkGroup'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

                others_rols.forEach(role_type_id => {
                    let rol = this.rolTypes.find(e => e.role_type_id == role_type_id)
                    rol.party_id = party_id
                    this.dataSupplier.usersGroup.push(rol)
                })

                this.dataSupplier.party_id = res.data.res.partyId
                this.dataSupplier.others_rols = []

                this.$emit('savedNewParty', {
                    partyId: res.data.res.partyId,
                    newParty: newParty
                })

            }).then(() => {
                this.setLoadingBtn(false)
            })

        },

        deleteUserWorkGroup(data) {

            console.log(data, this.dataSupplier)

            Vue.swal({
                html: "Está seguro de eliminar al usuario del grupo?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then(result => {
                if (result.isConfirmed) {

                    const {
                        party_id,
                    } = data

                    this.setUrl('lista-partys')
                    this.requestApi({
                        method: 'DELETE',
                        data: {
                            party_id,
                            group_part_id: this.dataSupplier.party_id,
                            typeDelete: 'deleteGroupMember'
                        }
                    }).then(res => {
                        console.log(res)
                        this.alertNotification({
                            param: {
                                html: res.data.detail
                            }
                        })

                        let index = this.dataSupplier.usersGroup.indexOf(data)
                        this.dataSupplier.usersGroup.splice(index, 1)

                    })

                }
            })

        },

        addWorkEffotType() {
            console.log(this.dataSupplier.workEffortPurposeType)
            this.dataSupplier.workEffortPurposeType.unshift({
                party_id: '',
                old_work_effort_type_id: '',
                old_work_effort_purpose_type_id: '',
                work_effort_type_id: '',
                work_effort_purpose_type_id: ''
            })
        },

        storeWorkEffotType() {

            console.log(this.dataSupplier)

            if (!this.$refs.form_Work_effot_type.validate())
                return false

            let {
                party_id,
                workEffortPurposeType
            } = this.dataSupplier
            this.setLoadingBtn(true)
            this.setUrl('lista-partys')

            this.requestApi({
                method: 'POST',
                data: {
                    workEffortPurposeType: workEffortPurposeType,
                    party_id,
                    typeStore: 'storeWorkEffotType'
                }
            }).then(res => {

                this.alertNotification({
                    param: {
                        html: res.data.res.msg
                    }
                })

            }).then(() => {
                this.setLoadingBtn(false)
            })

        },

        changeEmprendedor() {
            if (this.dataSupplier.preferences.emprendedor == true) {
                this.dataSupplier.preferences.micro_empresa = false
            } else {
                //this.dataSupplier.preferences.micro_empresa = true
            }


        },
        changeMicroEmpresa() {
            if (this.dataSupplier.preferences.micro_empresa == true) {
                this.dataSupplier.preferences.emprendedor = false
            } else {
                //this.dataSupplier.preferences.micro_empresa = true
            }


        },
        deleteWorkEffotType(data) {

            let index = this.dataSupplier.workEffortPurposeType.indexOf(data)

            if (data.party_id == '') {

                this.dataSupplier.workEffortPurposeType.splice(index, 1)

            } else {

                Vue.swal({
                    html: "Está seguro de eliminar esta asignación?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cerrar',
                    ...this.paramAlertQuestion
                }).then(result => {
                    if (result.isConfirmed) {

                        const {
                            party_id,
                            old_work_effort_purpose_type_id,
                            old_work_effort_type_id
                        } = data

                        this.setUrl('lista-partys')
                        this.requestApi({
                            method: 'DELETE',
                            data: {
                                party_id,
                                old_work_effort_purpose_type_id,
                                old_work_effort_type_id,
                                typeDelete: 'deleteWorkEffotType'
                            }
                        }).then(res => {

                            this.alertNotification({
                                param: {
                                    html: res.data.detail
                                }
                            })
                            this.dataSupplier.workEffortPurposeType.splice(index, 1)

                        })

                    }
                })

            }

        }

    },
    created() {

        this.getGeoData('ECU/provincias-pais', 'provinces')
        //this.status= this.dataParty.status
        this.typeIdentifications = this.dataParty.typeIdentifications
        this.contactMechPhonePurposeType = this.dataParty.contactMechPhonePurposeType
        this.contactMechEmailPurposeType = this.dataParty.contactMechEmailPurposeType
        this.contactMechAddressPurposeType = this.dataParty.contactMechAddressPurposeType
        this.paymentMethodTypes = this.dataParty.paymentMethodTypes
        this.partyClassificationsGroups = this.dataParty.partyClassificationsGroups
        this.finAccounts = this.dataParty.finAccounts
        this.retentionsIva = this.dataParty.retentions.filter(e => e.tipo_impuesto == 'IVA')
        this.retentionsRent = this.dataParty.retentions.filter(e => e.tipo_impuesto == 'RENTA')
        this.deadlines = this.dataParty.deadlines
        this.loadedFiles = this.dataParty.supplier.files
        this.rolTypes = this.dataParty.rolTypes
        this.companyTypes = this.dataParty.companyTypes
        this.workEffortsTypes = this.dataParty.workEffortsTypes
        this.workEffortPurposeType = this.dataParty.workEffortPurposeType
        this.rolTypesWG = this.dataParty.rolTypes.filter(e => e.parent_type_id == 'WORK_GROUP')
        this.banks = this.dataParty.banks
        this.users = this.dataParty.users
        this.dataTableAccountsToPay = this.dataParty.supplier.accountsToPay
        if (this.dataParty.supplier != null) this.dataParty.supplier.others_rols = []
        Object.assign(this.dataSupplier, this.dataParty.supplier)

    }
}
</script>