<template>
    <div>
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            :disable-pagination=true
            :fixed-header=true
            :loading=loadingTable
            :disable-sort="$vuetify.breakpoint.name == 'xs'"
            :key="`list-suppliers-${tenantId}`"
            :options.sync="options"
            hide-default-footer
            loading-text="Cargando proveedores..."
            no-results-text="No se han encontrado proveedores"
            no-data-text="Sin proveedores"
            class="elevation-1"
            dense
        >
            <template v-slot:top>
                <ExpandableFilters 
                    title="PROVEEDORES"
                    :filters=filters
                    titleButton='Nuevo'
                    iconToolbar='mdi-account-star'
                    classButton='py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0'
                    :actionButton="editSupplier"
                    @datafilters=getSuppliers
                />
            </template>
            <template v-slot:item.type="{ item }">
                {{item.type.split('_').join(' ').toUpperCase()}}
            </template>
            <template v-slot:footer>
                <v-pagination
                    class='mt-10'
                    v-model=currentPage
                    :length=totalItems
                    @input="handlePageChange"
                    total-visible="15"
                ></v-pagination>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-tooltip 
                    bottom
                    v-if="item.status_id != 'PARTY_TO_VALIDATE'"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  
                            v-bind="attrs"
                            v-on="on" 
                            icon 
                            @click="editSupplier(item.party_id)"
                        >
                            <v-icon color='orange'>mdi-account-edit</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>
                <v-tooltip 
                    bottom 
                    v-if="item.status_id=='PARTY_IN_PROCESS'"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn  
                            v-bind="attrs"
                            v-on="on" 
                            icon 
                            @click="sendApproval(item)"
                        >
                            <v-icon color='success'>mdi-send</v-icon>
                        </v-btn>
                    </template>
                    <span>Enviar para aprobación</span>
                </v-tooltip>
                <!-- <v-tooltip 
                    bottom 
                    v-if="validateSupplier && item.status_id == 'PARTY_TO_VALIDATE'"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn 
                            v-bind="attrs"
                            v-on="on" 
                            icon 
                            color='red' 
                            target="_blank" 
                            :to="`/verificar-proveedor/${item.party_id}`"
                        >
                        <v-icon>mdi-account-convert</v-icon>
                        </v-btn>
                    </template>
                    <span>Verificar</span>
                </v-tooltip> -->
            </template>
        </v-data-table>
        <PartyComponent 
            v-if=dialog
            :availableItems=availableItems
            :dialog=dialog
            :dataParty=dataParty
            roleDefault='SUPPLIER'
            @closeDialog="dialog= !dialog"
            @savedNewParty=savedNewParty
        />
    </div>
</template>

<script>

import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import Vue from 'vue'
import ExpandableFilters from '../general/ExpandableFilters'
import PartyComponent from '../general/PartyComponent'

export default {
    name:'SupplierComponent',
    components:{
        ExpandableFilters,
        PartyComponent
    },
    data:()=>({
        dataTable:[],
        setfilters:[],
        dialog:false,
        overlay:false,
        search:'',
        totalItems:1,
        currentPage:1,
        itemsPerPage:20,
        dataParty:{},
        availableItems:{},
        options: {},
        defaultAvailableItems:{
            generalData:true,
            contacts:true,
            tlfEMail:true,
            directions:true,
            preferences:true,
            paymentTypes:true,
            accountsToPay:true,
            uploadFiles:true
        },
        headers:[
            {text: 'Código', align: 'start', value: 'party_id'},
            {text: 'Nombre', align: 'start', value: 'name'},
            {text: 'Identificación', align: 'start', value: 'identification'},
            {text: 'Estado', align: 'start', value: 'status'},
            {text: 'Tipo', align: 'start', value: 'type'},
            {text: 'Actions', align: 'start', value: 'actions',align:'center'}
        ],
        filters:[
            {
                cols:6,
                class:'py-0 py-md-2 col-md-1 pl-0 pl-sm-2',
                v_model:'',
                label: 'Código',
                type:'input_text'
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pr-0 pr-sm-2',
                v_model:'',
                label: 'Identificación',
                type:'input_text'
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Nombres',
                type:'input_text'
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Apellidos',
                type:'input_text'
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-1 pl-0 pl-sm-2',
                v_model:'',
                label: 'Estado',
                type:'input_select',
                text:'name',
                value:'id',
                items:[
                    {id:'PARTY_ENABLED',name:'Habilitado'},
                    {id:'PARTY_IN_PROCESS', name:'En proceso'},
                    {id:'PARTY_TO_VALIDATE',name:'Por habilitar'},
                    {id:'PARTY_DISABLED',name:'Deshabilitado'}
                ],

            }
        ],
        required:[v => !!v || 'El campo es requerido'],
        emailValid:[
            v => !!v || 'Debe escribir un correo electrónico',
            v => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Debe escribir un correo válido'
        ]
    }),
    watch:{
        options: {
            handler () {
                this.getSuppliers(this.filters)
            },
            deep: true,
        },
    },
    computed:{
        
        ...mapState('purchases',['menu','urlFilesParty']),

        ...mapState('master',['loadingTable','tenantId','user']),

        ...mapGetters('access', ['validateSupplier']),

        url(){
            return this.$route.fullPath.split('/')[1]
        }

    },
    methods:{

        ...mapMutations('master',['setMenu','setUrl','setLoadingTable','setTitleToolbar']),

        ...mapActions('master',['requestApi','alertNotification']),

        handlePageChange(value) {
            this.currentPage = value
            this.getSuppliers(this.filters)
        },

        getSuppliers(filters){

            this.setLoadingTable(true)
            this.setfilters =filters
            this.dataTable=[]
            
            this.setfilters.forEach(obj=>{
               if(this.currentPage!=1  && obj.v_model!=''){
                   this.currentPage=1
                   return false;
               }
            })

            this.setUrl('lista-partys')
            this.requestApi({
				method: 'GET',
				data :{
                    page:this.currentPage,
                    itemsPerPage: this.itemsPerPage,
                    code: this.setfilters[0].v_model,
                    first_name:this.setfilters[2].v_model,
                    last_name:this.setfilters[3].v_model,
                    identification:this.setfilters[1].v_model,
                    status: this.setfilters[4].v_model,
                    typeList: 'suppliers',
                    paginator: true
                }
			}).then(res =>{
                console.log(res)
                this.totalItems = res.data.page_count
                this.dataTable = res.data._embedded.lista_partys
			}).then(()=>{
                this.setLoadingTable(false)
            })
        },

        editSupplier(party_id){
           
            let noPartyId = typeof party_id =='object'
            this.overlay=true
            this.setUrl(`lista-partys/${noPartyId ? 0 :party_id}`)
            
            this.requestApi({
                method: 'PATCH',
                data :{
                    urlFilesParty: this.urlFilesParty,
                    path: '/'+this.url
                }
            }).then(res =>{
                
                console.log(res)
                this.dataParty = res.data
                this.availableItems = noPartyId ? { generalData:true } : this.defaultAvailableItems
                this.dialog=true

            }).catch(()=>{
                
            }).then(()=>{

                this.setLoadingTable(false)
                this.overlay=false
                
            })
                       
        },

        savedNewParty(data){
            this.availableItems= this.defaultAvailableItems
            if(data.newParty){
                this.dialog=false
                this.editSupplier(data.partyId)
            }
            this.getSuppliers(this.filters)
        },

        sendApproval(item){

            Vue.swal({
                html: "Está seguro de enviar a validar este proveedor?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {
                if (result.isConfirmed) {
                    this.setUrl('lista-partys')

                    this.requestApi({
                        method: 'POST',
                        data :{
                            workEffortId: this.$route.params.workEffortId,
                            party_id: item.party_id,
                            status_id: 'PARTY_TO_VALIDATE',
                            work_effort_name: 'Proveedor para validación de datos',
                            work_effort_type_id :'VERIFICACION_DATOS',
                            work_effort_purpose_type_id: 'WEPT_VERIF_PROVEEDOR',
                            role_type_id:'SUPPLIER_EDIT',
                            description:"El usuario "+this.user.user_ligin_id+" ha enviado una tarea del proveedor "+item.name+" para validar sus datos",
                            priority: 5,
                            work_effort_content_type_id:'URL',
                            content_name:`/verificar-proveedor/${item.party_id}`,
                            typeStore:'storeWorkEffort'
                        }
                    }).then(res =>{

                        this.alertNotification({param:{html: res.data.res.msg}})
                        this.getSuppliers(this.filters)

			        })
                }
            })
            
        }
    },
    mounted(){

        if(typeof this.$route.params.partyId != 'undefined' && typeof this.$route.params.workEffortId != 'undefined'){
            this.filters[0].v_model = this.$route.params.partyId
            this.filters[4].v_model = 'PARTY_IN_PROCESS'
        }

        typeof this.$route.params.partyId != 'undefined' && 
            this.editSupplier(this.$route.params.partyId)
    }

}
</script>

<style>
    .v-dialog{
        background-color: white;
    }
    .v-divider--inset{
        max-width: 100%!important;
        margin-left:10px!important;
        border-color: rgb(212 212 212)!important;
    }
</style>